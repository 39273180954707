import React, { useEffect } from "react";
import { Button, Typography } from "@mui/material";
import { Link } from "components";

const Unauthorized = () => {
  useEffect(() => {
    localStorage.removeItem("client");
  }, []);

  return (
    <div className="text-center">
      <div className="mb-5">
        <Typography variant="subtitle2">Error: 401</Typography>
        <Typography variant="h4">Unauthorized!</Typography>
      </div>
      <Button component={Link} variant="contained" color="primary" to="/login">
        Login
      </Button>
    </div>
  );
};

export default Unauthorized;
