import React, { useState, useEffect } from "react";
import { log } from "../../../../api/clientApi";
import { parseISO, format } from "date-fns";
import { Typography } from "@mui/material";

const Log = ({ phone }) => {
  const [logs, setLogs] = useState(null);
  useEffect(() => {
    log(phone)
      .then((result) => {
        setLogs(result);
      })
      .catch((err) => console.error(err));
  }, [phone]);

  return (
    <>
      {logs &&
        logs.map((log) => (
          <div className="py-3 border-b" key={log._id}>
            <Typography variant="body2" gutterBottom>
              {format(parseISO(log.createdAt), "MM/dd/yyyy hh:mm bbb")}
            </Typography>
            <div style={{ whiteSpace: "pre-wrap" }}>
              <Typography variant="body1">{log.summary}</Typography>
            </div>
          </div>
        ))}
    </>
  );
};

export default Log;
