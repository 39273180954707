import React from "react";
import { ThemeProvider } from "@mui/material/styles";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import theme from "./theme";
import store from "./app/store";
import { Provider } from "react-redux";
import { Routes, Route } from "react-router-dom";
import { Auth, Dashboard } from "./layouts";
import { history, Router } from "./history";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  Forgot,
  NotFound,
  Login,
  Logout,
  QuoteRequests,
  Text,
  TextBulk,
  ResetPassword,
  Unauthorized,
} from "./views";

const App = () => {
  return (
    <Provider store={store}>
      <ThemeProvider theme={theme}>
        <ToastContainer />
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <Router history={history}>
            <Routes>
              <Route path="/dashboard" element={<Dashboard />}>
                <Route path="quote-requests" element={<QuoteRequests />} />
                <Route path="text-bulk" element={<TextBulk />} />
                <Route path="text" element={<Text />} />
                <Route path="*" element={<NotFound />} />
              </Route>
              <Route path="/" element={<Auth />}>
                <Route index element={<Login />} />
                <Route path="login" element={<Login />} />
                <Route path="logout" element={<Logout />} />
                <Route path="forgot" element={<Forgot />} />
                <Route
                  path="reset-password/:email"
                  element={<ResetPassword />}
                />
                <Route path="unauthorized" element={<Unauthorized />} />
                <Route path="*" element={<NotFound />} />
              </Route>
            </Routes>
          </Router>
        </LocalizationProvider>
      </ThemeProvider>
    </Provider>
  );
};

export default App;
