import React, { useEffect, useState } from "react";
import { Loading } from "components";
import { SearchBar, List } from "./components";
import { getQuoteRequests } from "api/quoteRequests";
import { Link, Typography } from "@mui/material";

const QuoteRequests = () => {
  const [records, setRecords] = useState([]);
  const [filtered, setFiltered] = useState([]);
  const [search, setSearch] = useState(null);
  const [loading, setLoading] = useState("idle");

  useEffect(() => {
    setLoading("loading");

    getQuoteRequests()
      .then((data) => {
        setLoading("succeeded");
        setRecords(data);
        setFiltered(data);
      })
      .catch((err) => {
        setLoading("failed");
      });
  }, []);

  const handleSearch = (term) => {
    setSearch(term && term.length ? term.toLowerCase() : null);
  };

  return (
    <>
      <div className="p-4">
        <SearchBar handleSearch={handleSearch} />
      </div>
      <div className="p-4">
        <Loading status={loading} />
        <div className="py-3">
          <Link href="/dashboard/text-bulk/">Bulk Texting</Link>
        </div>
        <>
          {search ? (
            <Typography variant="body2">Search: {search}</Typography>
          ) : (
            ""
          )}
        </>
        <>
          {loading === "succeeded" && (
            <>
              {filtered && filtered.length ? (
                <List result={filtered} search={search} />
              ) : (
                <>0 records</>
              )}
            </>
          )}
        </>
      </div>
    </>
  );
};

export default QuoteRequests;
