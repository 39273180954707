import typography from "../typography";

const MuiAlert = {
  styleOverrides: {
    root: {
      ...typography.body1,
    },
  },
};

export default MuiAlert;
