import React, { useState, useEffect } from "react";
import {
  Button,
  IconButton,
  InputAdornment,
  TextField,
  Typography,
  Alert,
  Link,
} from "@mui/material";
import { useForm, Controller } from "react-hook-form";
import { login } from "api/clientApi";
import { useNavigate, useSearchParams } from "react-router-dom";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { Link as LinkRouter } from "components";

const Login = () => {
  const navigate = useNavigate();
  const { handleSubmit, control } = useForm();
  const [showPassword, setShowPassword] = useState(false);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [searchParams] = useSearchParams();

  useEffect(() => {
    // load token
    const client = JSON.parse(localStorage.getItem("client"));
    if (client && client.token) {
      // redirect
      navigate("/dashboard/quote-requests");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const onSubmit = (data) => {
    login(data)
      .then((data) => {
        localStorage.setItem("client", JSON.stringify(data));
        navigate("/dashboard/quote-requests");
      })
      .catch((err) => {
        console.error(err);
        setLoading(false);
        if (err.response && err.response.status === 422) {
          setError("Invalid username/password");
        }
      });
  };

  return (
    <div className="w-full max-w-md">
      <div className="mb-6">
        <Typography variant="h1" align="center">
          YourQuote
        </Typography>
      </div>
      <div className="p-8 border-solid border rounded-md mx-4 bg-indigo-50">
        <div className="mb-10">
          <Typography variant="h4" align="center">
            Log in to your account
          </Typography>
        </div>

        {error && (
          <div className="pb-10">
            <Alert severity="error">{error}</Alert>
          </div>
        )}

        {searchParams.get("reset") && (
          <div className="pb-10">
            <Alert severity="info">Password reset successfully</Alert>
          </div>
        )}

        <form onSubmit={handleSubmit(onSubmit)} noValidate>
          <div className="mb-5">
            <Controller
              name="email"
              control={control}
              defaultValue=""
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <TextField
                  fullWidth
                  label="Email"
                  variant="outlined"
                  value={value}
                  onChange={onChange}
                  error={!!error}
                  helperText={error ? error.message : null}
                />
              )}
              rules={{
                required: "Email is required",
                pattern: {
                  value: /\S+@\S+\.\S+/,
                  message: "Entered value is not a valid email format",
                },
              }}
            />
          </div>

          <div className="mb-5">
            <Controller
              name="password"
              control={control}
              defaultValue=""
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <TextField
                  fullWidth
                  label="Password"
                  variant="outlined"
                  value={value}
                  onChange={onChange}
                  error={!!error}
                  helperText={error ? error.message : null}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword}
                          onMouseDown={handleMouseDownPassword}
                          edge="end"
                        >
                          {showPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                  type={showPassword ? "text" : "password"}
                />
              )}
              rules={{
                required: "Password required",
                minLength: {
                  value: 6,
                  message: "min length is 6",
                },
              }}
            />
          </div>

          <div className="flex justify-between items-center">
            <Button type="submit" variant="contained" color="primary">
              {loading ? "loading..." : "Login"}
            </Button>
            <Link component={LinkRouter} to="/forgot">
              Forgot your password?
            </Link>
          </div>
        </form>
      </div>
    </div>
  );
};

export default Login;
