import React, { useState, useEffect } from "react";
import {
  Button,
  TextField,
  Typography,
  Alert,
  Link,
  InputAdornment,
  IconButton,
} from "@mui/material";
import { useForm, Controller } from "react-hook-form";
import { resetPassword } from "api/clientApi";
import { useNavigate, useParams } from "react-router-dom";
import { Link as LinkRouter } from "components";
import clsx from "clsx";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import Visibility from "@mui/icons-material/Visibility";

const ResetPassword = () => {
  const navigate = useNavigate();
  const { email } = useParams();
  const { handleSubmit, control } = useForm();
  const [error, setError] = useState(null);
  const [step, setStep] = useState(1);
  const [loading, setLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const onSubmit = (data) => {
    setLoading(true);
    setError(null);

    resetPassword({
      email,
      ...data,
    })
      .then((result) => {
        setLoading(false);
        if (step === 1) {
          setStep(2);
        } else {
          navigate(`/login?reset=true`);
        }
      })
      .catch((err) => {
        console.error(err);
        setLoading(false);

        if (err.response && err.response.data && err.response.data.message) {
          setError(err.response.data.message);
        }
      });
  };

  return (
    <div className="w-full max-w-md p-8 border-solid border rounded-md mx-4 bg-indigo-50">
      <div className="mb-6">
        <Typography variant="h4" align="center">
          Reset Password
        </Typography>
      </div>

      <div className="mb-10">
        <Typography>
          Enter the pin code that you have received on the registered phone.
        </Typography>
      </div>

      {error && (
        <div className="pb-10">
          <Alert severity="error">{error}</Alert>
        </div>
      )}

      <form onSubmit={handleSubmit(onSubmit)} noValidate>
        <div className={clsx("mb-5", step === 2 && "hidden")}>
          <Controller
            name="pin"
            control={control}
            defaultValue=""
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <TextField
                fullWidth
                label="Pin code"
                variant="outlined"
                value={value}
                onChange={onChange}
                error={!!error}
                helperText={error ? error.message : null}
              />
            )}
            rules={{
              validate: {
                minLength: (pin) => {
                  if (step === 1 && pin.length < 6) {
                    return "Pin code should be minimum 6 characters long";
                  }
                },
              },
            }}
          />
        </div>

        <div className={clsx("mb-5", step === 1 && "hidden")}>
          <Controller
            name="password"
            control={control}
            defaultValue=""
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <TextField
                fullWidth
                label="New Password"
                variant="outlined"
                value={value}
                onChange={onChange}
                error={!!error}
                helperText={error ? error.message : null}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                        edge="end"
                      >
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                type={showPassword ? "text" : "password"}
              />
            )}
            rules={{
              validate: {
                minLength: (v) => {
                  if (step === 2 && v.length < 6) {
                    return "Password should be minimum 6 characters long";
                  }
                },
              },
            }}
          />
        </div>

        <div className="flex justify-between items-center">
          <Button type="submit" variant="contained" color="primary">
            {loading
              ? "loading..."
              : step === 1
              ? "Verify Pin"
              : "change password"}
          </Button>
          <Link component={LinkRouter} to="/login">
            Log In
          </Link>
        </div>
      </form>
    </div>
  );
};

export default ResetPassword;
