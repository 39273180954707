import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getQuoteRequests } from "api/quoteRequests";
import { normalize, schema } from "normalizr";

const quoteRequest = new schema.Entity("quoteRequest");
const quoteRequestsSchema = [quoteRequest];

export const fetchQuoteRequests = createAsyncThunk(
  "quoteRequests/fetchQuoteRequests",
  async (params) => await getQuoteRequests(params)
);

export const quoteRequestsSlice = createSlice({
  name: "quoteRequests",
  initialState: {
    active: null,
    data: { entities: { quoteRequests: {} }, result: [] },
    status: "idle",
    error: null,
  },
  reducers: {
    // state here is the slice state and not the root state
  },
  extraReducers(builder) {
    builder
      .addCase(fetchQuoteRequests.pending, (state, action) => {
        state.status = "loading";
      })
      .addCase(fetchQuoteRequests.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.data = normalize(action.payload, quoteRequestsSchema);
      })
      .addCase(fetchQuoteRequests.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      });
  },
});

export default quoteRequestsSlice.reducer;

export const selectAllQuoteRequests = (state) => {
  return state.quoteRequests.data;
};

export const selectQuoteRequestById = (state, quoteRequestId) => {
  return (
    state.quoteRequests.data.entities.quoteRequests[quoteRequestId] || null
  );
};
