import React, { useEffect } from "react";
import { Link } from "components";
import { Button, Typography } from "@mui/material";

const Logout = () => {
  useEffect(() => {
    localStorage.removeItem("client");
  }, []);

  return (
    <div className="m-5 text-center">
      <div className="mb-5">
        <Typography align="center" variant="h2" gutterBottom>
          Logged out
        </Typography>
        <Typography align="center">
          You have successfully logged out.
        </Typography>
      </div>

      <Button
        color="primary"
        component={Link}
        disableElevation
        to="/login"
        variant="contained"
      >
        Log In Again
      </Button>
    </div>
  );
};

export default Logout;
