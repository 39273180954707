import { CANADIAN_AREA_CODES } from "../app/config";

export const validCanadianNumber = (value) => {
  if (value.length === 0) {
    return true;
  } else {
    const areaCode = Number(value.substr(0, 3));
    return CANADIAN_AREA_CODES.includes(areaCode);
  }
};

export const toUpperCaseEachWord = (str) => {
  const words = str.split(" ");
  for (let i = 0; i < words.length; i++) {
    if (words[i][0]) {
      words[i] = words[i][0].toUpperCase() + words[i].slice(1);
    }
  }

  return words.join(" ");
};
