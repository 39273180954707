import React, { useState, useEffect } from "react";
import {
  Button,
  IconButton,
  InputAdornment,
  TextField,
  Typography,
  Alert,
  Link,
} from "@mui/material";
import { useForm, Controller } from "react-hook-form";
import { validateEmailCellNo } from "api/clientApi";
import { useNavigate } from "react-router-dom";
import { Link as LinkRouter } from "components";

const Forgot = () => {
  const navigate = useNavigate();
  const { handleSubmit, control } = useForm();
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);

  const onSubmit = (data) => {
    setLoading(true);

    validateEmailCellNo(data)
      .then((result) => {
        navigate(`/reset-password/${data.email}`);
      })
      .catch((err) => {
        console.error(err);
        setLoading(false);

        if (err.response && err.response.data && err.response.data.message) {
          setError(err.response.data.message);
        }
      });
  };

  return (
    <div className="w-full max-w-md p-8 border-solid border rounded-md mx-4 bg-indigo-50">
      <div className="mb-6">
        <Typography variant="h4" align="center">
          Reset Password
        </Typography>
      </div>

      <div className="mb-10">
        <Typography>
          Enter your email address and phone number and we will text you a
          temporary password.
        </Typography>
      </div>

      {error && (
        <div className="pb-10">
          <Alert severity="error">{error}</Alert>
        </div>
      )}

      <form onSubmit={handleSubmit(onSubmit)} noValidate>
        <div className="mb-5">
          <Controller
            name="email"
            control={control}
            defaultValue=""
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <TextField
                fullWidth
                label="Email"
                variant="outlined"
                value={value}
                onChange={onChange}
                error={!!error}
                helperText={error ? error.message : null}
              />
            )}
            rules={{
              required: "Email is required",
              pattern: {
                value: /\S+@\S+\.\S+/,
                message: "Entered value is not a valid email format",
              },
            }}
          />
        </div>

        <div className="mb-5">
          <Controller
            name="cellNo"
            control={control}
            defaultValue=""
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <TextField
                fullWidth
                label="Phone"
                variant="outlined"
                value={value}
                onChange={onChange}
                error={!!error}
                helperText={error ? error.message : null}
              />
            )}
            rules={{
              required: "Phone is required",
              pattern: {
                value: /^\d{10}$/,
                message: "Entered a valid 10 digit only phone number",
              },
            }}
          />
        </div>

        <div className="flex justify-between items-center">
          <Button type="submit" variant="contained" color="primary">
            {loading ? "loading..." : "Reset Password"}
          </Button>
          <Link component={LinkRouter} to="/login">
            Log In
          </Link>
        </div>
      </form>
    </div>
  );
};

export default Forgot;
