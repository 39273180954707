import React from "react";
import { Badge, Button, Link, Typography } from "@mui/material";
import { format, parseISO } from "date-fns";
import { styled } from "@mui/system";
import { CSVLink } from "react-csv";

const List = ({ result, search }) => {
  const ExternalLinks = styled("div")(({ theme }) => ({
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    paddingTop: ".6rem",
  }));

  return (
    <>
      {result && result.length && (
        <CSVLink
          data={result.map(({ name, email, phone }) => {
            return { name, email, phone };
          })}
          filename="quote-request-contacts.csv"
        >
          Download CSV
        </CSVLink>
      )}
      {result
        .filter((record) => {
          if (search && search.length) {
            return (
              (record.name && record.name.toLowerCase().includes(search)) ||
              (record.email && record.email.toLowerCase().includes(search)) ||
              (record.phone && record.phone.includes(search))
            );
          } else {
            return record;
          }
        })
        .map((record) => (
          <div
            key={record._id}
            className="grid grid-cols-1 md:grid-cols-3 xl:grid-cols-4 gap-4 border-b border-indigo-100 py-10"
          >
            <div className="md:col-span-3 xl:col-span-4">
              <Typography variant="body2" gutterBottom>
                {format(parseISO(record.createdAt), "dd MMM yyyy")}
              </Typography>
            </div>
            <div>
              <Typography variant="body1">{record.name}</Typography>
              <Typography variant="body1">
                {record.email ? (
                  <Link href={`mailto:${record.email}`}>{record.email}</Link>
                ) : (
                  "-"
                )}
              </Typography>
              <Typography variant="body1" gutterBottom>
                {record.phone ? (
                  <Link href={`tel:+1${record.phone}`}>{record.phone}</Link>
                ) : (
                  "-"
                )}
              </Typography>
            </div>
            <div className="md:col-span-2 xl:col-span-3">
              <Badge color="secondary" badgeContent={record.count || "-"}>
                <Button
                  component={Link}
                  href={record.url}
                  target="_blank"
                  variant="outlined"
                  color="primary"
                  size="small"
                >
                  view Quote
                </Button>
              </Badge>

              <ExternalLinks>
                <Link href={record.url} target="_blank">
                  {record.surl
                    ? record.surl
                    : `${process.env.REACT_APP_SHORT_URL_HOST}/${record._id}`}
                </Link>
              </ExternalLinks>

              {record.phone && (
                <div className="mt-4">
                  <Link
                    color="primary"
                    href={`/dashboard/text?to=${record.phone}`}
                  >
                    Send Text
                  </Link>
                </div>
              )}
            </div>
          </div>
        ))}
    </>
  );
};

export default List;
