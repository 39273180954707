import React, { useLayoutEffect, useState } from "react";
// import Router and not Browser router because we are mounting history object ourself;
// if BrowserRouter is imported; history.push and other functions will not work as expected;
import { Router } from "react-router-dom";

// use this customRouter component instead of default router;
// because it takes in history object that could be used in another non-functional component codes; like in axios interceptor

const CustomRouter = ({ history, ...props }) => {
  const [state, setState] = useState({
    action: history.action,
    location: history.location,
  });

  useLayoutEffect(() => history.listen(setState), [history]);

  return (
    <Router
      {...props}
      location={state.location}
      navigationType={state.action}
      navigator={history}
    />
  );
};

export default CustomRouter;
