import React from "react";
import { Button, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";

const NotFound = () => {
  const navigate = useNavigate();

  return (
    <div className="text-center">
      <div className="mb-5">
        <Typography variant="subtitle2">Error: 404</Typography>
        <Typography variant="h4">Component Not Found!</Typography>
      </div>
      <Button
        variant="contained"
        color="primary"
        onClick={() => {
          navigate(-1);
        }}
      >
        Back
      </Button>
    </div>
  );
};

export default NotFound;
