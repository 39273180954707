import React, { useState, useEffect } from "react";
import { useForm, Controller } from "react-hook-form";
import {
  Alert,
  Button,
  FormControl,
  NativeSelect,
  TextField,
  Link,
  Typography,
} from "@mui/material";
import { useSearchParams } from "react-router-dom";
import { textBulk } from "../../api/clientApi";
import jwtDecode from "jwt-decode";
import Papa from "papaparse";
import { validCanadianNumber, toUpperCaseEachWord } from "../../utils";

const TextBulk = () => {
  const [contacts, setContacts] = useState([]);
  let [searchParams, setSearchParams] = useSearchParams();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isSuccess, setIsSuccess] = useState(null);
  const [isError, setIsError] = useState(null);
  const [msgSegments, setMsgSegments] = useState(1);
  const [signature, setSignature] = useState("");
  const {
    control,
    handleSubmit,
    formState: { errors },
    setValue,
    getValues,
  } = useForm({
    defaultValues: {
      message: searchParams.get("msg") || "",
    },
  });

  const onSubmit = ({ message }) => {
    if (contacts && contacts.length && message) {
      setIsError(null);
      setIsSubmitting(true);
      textBulk({
        contacts,
        message,
      })
        .then((result) => {
          setIsSuccess(true);
        })
        .catch((err) => {
          setIsError(err.message);
          setIsSubmitting(false);
        });
    } else {
      setIsError("Invalid input");
    }
  };

  useEffect(() => {
    const storage = JSON.parse(localStorage.getItem("client") || {});
    if (storage.token) {
      const { name, email, domain } = jwtDecode(storage.token);
      setSignature(`${toUpperCaseEachWord(name)}\n${email}\n${domain}`);
    }
  }, []);

  useEffect(() => {
    setValue(
      "message",
      searchParams.get("msg") ||
        `Dear {name}\nYour msg comes here\n${signature}`
    );
    setMsgSegments(
      (searchParams.get("msg") &&
        Math.ceil(searchParams.get("msg").length / 153)) ||
        1
    );
  }, [searchParams.get("msg"), signature]);

  const handleOnChange = (e) => {
    Papa.parse(e.target.files[0], {
      header: true,
      skipEmptyLines: true,
      complete: function (results) {
        const contacts = [];
        results.data.forEach(({ name, phone: rawPhone }) => {
          if (rawPhone) {
            const phone = rawPhone.replace(/\D/g, "");
            if (phone.length === 10 && validCanadianNumber(phone)) {
              contacts.push({ name, phone });
            }
          }
        });
        setContacts(contacts);
      },
    });
  };

  return (
    <div className="p-4">
      <div className="py-3">
        <Link href="/dashboard/quote-requests">Back to quotes</Link>
      </div>
      {isError && (
        <Alert severity="error">
          {isError || "There is an error sending message"}
        </Alert>
      )}
      {isSuccess ? (
        <Alert severity="success">Messages are successfully sent</Alert>
      ) : (
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="py-3 max-w-md">
            <input
              type={"file"}
              id={"csvFileInput"}
              accept={".csv"}
              onChange={handleOnChange}
            />
          </div>

          <div className="py-3 max-w-md">
            <Controller
              name="message"
              control={control}
              render={({ field }) => (
                <TextField
                  variant="outlined"
                  label="Message"
                  multiline
                  minRows={6}
                  fullWidth
                  {...field}
                  onChange={(e) => {
                    field.onChange(e);
                    if (getValues("message").length) {
                      setMsgSegments(
                        Math.ceil(getValues("message").length / 153)
                      );
                    }
                  }}
                />
              )}
            />
          </div>

          <div className="py-3">
            <Button
              color="primary"
              disableElevation
              disabled={isSubmitting}
              type="submit"
              variant="contained"
            >
              {isSubmitting ? "Submitting" : "Submit"}
            </Button>
            <Typography
              variant="body1"
              style={{ marginTop: "1rem", marginBottom: "1rem" }}
            >
              Estimated Message Count {msgSegments}
            </Typography>
          </div>
          <Typography variant="h4" gutterBottom>
            Imported Contacts ({contacts.length})
          </Typography>
          <ol>
            {contacts &&
              contacts.map((row, idx) => (
                <li key={idx}>
                  {row.phone} {row.name}
                </li>
              ))}
          </ol>
        </form>
      )}
    </div>
  );
};

export default TextBulk;
