import React from "react";
import { Outlet } from "react-router-dom";
import { styled } from "@mui/material/styles";

const Wrapper = styled("div")(({ theme }) => ({
  backgroundImage: "url('/images/bg-light.jpg')",
  backgroundRepeat: "no-repeat",
  backgroundSize: "cover",
}));

const Auth = ({ children }) => (
  <Wrapper className="layout layout-auth flex min-h-screen w-full justify-center items-center bg-gray-50">
    <Outlet />
  </Wrapper>
);

export default Auth;
