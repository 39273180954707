import React from "react";
import { CircularProgress } from "@mui/material";

const Loading = ({ status }) => {
  if (status === "loading") {
    return <CircularProgress />;
  } else {
    return <></>;
  }
};

export default Loading;
