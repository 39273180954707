export const provincesData = {
  AB: "Alberta",
  BC: "British Columbia",
  MB: "Manitoba",
  NB: "New Brunswick",
  NL: "Newfoundland and Labrador",
  NT: "Northwest Territories",
  NS: "Nova Scotia",
  NU: "Nunavut",
  ON: "Ontario",
  PE: "Prince Edward Island",
  QC: "Quebec",
  SK: "Saskatchewan",
  YT: "Yukon Territory",
};

export const provinces = [
  "AB",
  "BC",
  "MB",
  "NB",
  "NL",
  "NT",
  "NS",
  "NU",
  "ON",
  "PE",
  "QC",
  "SK",
  "YT",
];

export const CANADIAN_AREA_CODES = [
  204, 226, 236, 249, 250, 263, 289, 306, 343, 354, 365, 367, 368, 403, 416,
  418, 431, 437, 438, 450, 468, 474, 506, 514, 519, 548, 579, 581, 584, 587,
  604, 613, 639, 647, 672, 683, 705, 709, 742, 753, 778, 780, 782, 807, 819,
  825, 867, 873, 902, 905,
];
