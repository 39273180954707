import React, { forwardRef } from "react";
import { Link as MuiLink } from "@mui/material";
import { Link as RouterLink } from "react-router-dom";

// mui Button also need ref; where we are planing to use this component;
// so we have to use forwardRef;

const Link = forwardRef((props, ref) => (
  <MuiLink component={RouterLink} {...props} ref={ref} />
));

export default Link;
