import React from "react";
import { IconButton, Typography } from "@mui/material";
import { Link } from "components";
import { Outlet } from "react-router-dom";
import LogoutIcon from "@mui/icons-material/Logout";
import { styled } from "@mui/material/styles";

const Header = styled("div")(({ theme }) => ({
  backgroundImage: "url('/images/bg-light.jpg')",
  backgroundRepeat: "no-repeat",
  backgroundSize: "cover",
}));

const Dashboard = () => (
  <main className="flex flex-col flex-auto min-h-screen min-w-0 relative">
    <Header className="p-4 border-solid border-b border-black-100 flex justify-between items-center">
      <Typography variant="h3">YourQuote</Typography>
      <IconButton aria-label="Logout" component={Link} to="/logout">
        <LogoutIcon />
      </IconButton>
    </Header>
    <div>
      <Outlet />
    </div>
  </main>
);

export default Dashboard;
