import React from "react";
import { useForm, Controller } from "react-hook-form";
import { Input } from "@mui/material";
import { useNavigate } from "react-router-dom";
import InputAdornment from "@mui/material/InputAdornment";
import SearchIcon from "@mui/icons-material/Search";
//import { styled } from "@mui/material";

const SearchBar = ({ handleSearch }) => {
  const { handleSubmit, control } = useForm();
  const navigate = useNavigate();

  return (
    <form
      onSubmit={(e) => {
        e.preventDefault();
      }}
    >
      <Controller
        name="search"
        control={control}
        defaultValue=""
        render={({ field: { onChange, value } }) => (
          <Input
            fullWidth
            placeholder="Search by name, email or phone"
            value={value}
            onChange={(e) => {
              onChange(e);
              const term = e.target.value;

              if (term && term.length > 2) {
                handleSearch(term);
              } else {
                handleSearch(null);
              }
            }}
            type="search"
            input={{ backgroundColor: "red" }}
            startAdornment={
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            }
          />
        )}
      />
    </form>
  );
};

export default SearchBar;
