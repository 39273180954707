import axios from "./axios";

const baseUrl = "/clients";

export function login(data) {
  return axios({
    method: "post",
    url: `${baseUrl}/login`,
    data,
  });
}

export function textBulk(data) {
  return axios({
    method: "post",
    url: `${baseUrl}/text-bulk`,
    data,
  });
}

export function text(data) {
  return axios({
    method: "post",
    url: `${baseUrl}/text`,
    data,
  });
}

export function log(phone) {
  return axios({
    method: "get",
    url: `${baseUrl}/log`,
    params: { phone },
  });
}

export function validateEmailCellNo(data) {
  return axios({
    method: "post",
    url: `${baseUrl}/validateEmailCellNo`,
    data,
  });
}

export function resetPassword(data) {
  return axios({
    method: "post",
    url: `${baseUrl}/resetPassword`,
    data,
  });
}
